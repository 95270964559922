<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title class="mb-4">
        <span>{{ campaign.name }}</span>
      </v-card-title>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col>
            <v-text-field
              label="시작 날짜"
              v-model="campaign.start_date"
              readonly
              filled
              background-color="#EFEFF7"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="종료 날짜"
              v-model="campaign.end_date"
              readonly
              filled
              background-color="#EFEFF7"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-combobox
              v-model="campaign.categories"
              label="카테고리"
              multiple
              chips
              readonly
              filled
              background-color="#EFEFF7"
              hide-details
              append-icon=""
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-combobox
              v-model="campaign.medias"
              label="언론사"
              multiple
              chips
              readonly
              filled
              background-color="#EFEFF7"
              hide-details
              append-icon=""
            >
            </v-combobox>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <v-text-field
              label="클라이언트"
              v-model="campaign.clients"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="정산 조건"
              v-model="campaign.settlement"
              readonly
              filled
              background-color="#EFEFF7"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="mt-6">
      <v-card-title class="mb-4">문항 현황</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          elevation="0"
          :loading="loading"
          :loading-text="loading_txt"
          no-data-text="데이터가 없습니다."
        >
          <template v-slot:[`item.keywords`]="{ item }">
            <v-chip-group column>
              <v-chip
                v-for="tag in item.arrKeywords"
                :key="tag"
                label
                outlined
                color="blue darken-3 white--text"
              >
                {{ "#" + tag }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:[`item.response_count`]="{ item }">
            {{ item.response_count }}{{ "/" + item.response_limit }}
          </template>
          <!-- <template v-slot:[`item.status`]="{ item }">
            <v-switch
              inset
              v-model="item.status"
              :true-value="1"
              :false-value="0"
              color="green darken-1"
              @change="changeState(item.id)"
              :readonly="statusReadonly"
            ></v-switch>
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CampaignDetail",
  data() {
    return {
      campaign: {},
      items: [],
      headers: [
        {
          text: "No",
          value: "no",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "내용",
          value: "description",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "타입",
          value: "type",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "키워드",
          value: "keywords",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "보기",
          value: "questions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "응답/제한",
          value: "response_count",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        // {
        //   text: "ON/OFF",
        //   value: "status",
        //   sortable: false,
        //   filterable: false,
        //   class: "font-weight-black subtitle-2",
        // },
      ],
      loading: false,
      loading_txt: "로딩중.. 기다려주세요.",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let items = [];
      this.axios
        .get("api/v1/admin/campaigns/" + this.$route.params.id)
        .then((res) => {
          if (res.data.data.surveys.length > 0) {
            res.data.data.surveys.map((item, index) => {
              items.push({
                no: index + 1,
                id: item.id,
                description: item.description,
                type: item.type,
                response_count: item.response_count,
                response_limit: item.response_limit,
                questions: item.questions.map((item) => item.question),
                keywords: item.keywords,
                arrKeywords: item.keywords
                  ?.split("#")
                  .filter((element, index) => index > 0),
                status: item.status,
              });
            });
          }
          this.items = items;
          this.campaign = {
            name: res.data.data.name,
            start_date: res.data.data.start_date.substr(0, 10),
            end_date: res.data.data.end_date.substr(0, 10),
            medias: res.data.data.medias.map((item) => item.name),
            categories: res.data.data.categories.map((item) => item.name),
            clients: res.data.data.client.name,
            settlement: `응답 ${res.data.data.medias[0].calculate_unit}회당 / ${res.data.data.medias[0].calculate_price}원`,
          };
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
